import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/redpack/:redPackId',
    name: 'redpack',
    component: () => import('./view/redpack'),
    meta: {
      title: '红包二维码'
    }
  },{
    name: 'goods',
    component: () => import('./view/goods'),
    meta: {
       title: '商品详情'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
