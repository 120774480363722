import Vue from 'vue';
import App from './App';
import { router } from './router';
import { Overlay } from 'vant';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Overlay);
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
